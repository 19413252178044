import isomorphicFetch from 'isomorphic-fetch'

export default (url, method, body) => {
	const options = {
		method,
		headers: requestHeaders(),
		body: method !== 'GET' ? JSON.stringify(body) : null,
	}

	return isomorphicFetch(url, options).then((res) => parseResponseType(res))
}

function parseResponseType(res) {
	let filename = ''
	const { status } = res
	return new Promise((resolve, reject) => {
		if (status >= 200 && status < 300) {
			if (res.headers.has('Content-Disposition')) {
				const disposition = res.headers.get('Content-Disposition')
				const filenameRegex = /filename[^;\n]*=(UTF-\d['"]*)?((['"]).*?[.]$\2|[^;\n]*)?/gi
				const matches = disposition.match(filenameRegex)
				if (matches != null && matches[1]) {
					const filenameCyrillic = matches[1].replace(
						// eslint-disable-next-line
						/filename\*\=utf\-8\''/g,
						''
					)
					filename = decodeURIComponent(filenameCyrillic)
				}
				res.blob().then((blob) => {
					var url = window.URL.createObjectURL(blob)
					var a = document.createElement('a')
					a.href = url
					a.download = filename
					document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
					a.click()
					a.remove() //afterwards we remove the element again
				})
			} else {
				res.json().then((response) => resolve(response))
			}
		} else {
			res.json().then((response) => {
				reject({ status, response })
			})
		}
	})
}

function requestHeaders() {
	return {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'x-request-type-id': window.xRequestTypeId ? window.xRequestTypeId : '',
	}
}
